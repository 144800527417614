<template>
    <form
        v-on-clickaway="clickAway"
        @submit.prevent="submitHandler"
        class="comment-form"
        :class="{
            'to-left': direction.horizonatal==='left',
            'to-right': direction.horizonatal==='right',
            'to-top': direction.vertical === 'top',
            'to-bottom': direction.vertical === 'bottom',
        }"
        :style="`--position-x: ${position.x}px; --position-y:${position.y}px;`">
        <div class="filed-wrapper">
            <div class="title">
                <div class="symbols">
                    {{ (comment ? comment.length : 0) + "/" + maxCommentLength }}
                </div>
            </div>
            <input
                type="text"
                v-model="comment"
                :maxlength="maxCommentLength"
                placeholder="Leave comment, or type “/” to tag">
        </div>
        <div class="controlls">
            <button
                type="button"
                class="cancel"
                @click="$emit('cancel')">
                Cancel
            </button>
            <button
                type="submit"
                class="post"
                :class="{'active-post-btn': comment && comment.trim()}">
                Post
            </button>
        </div>
    </form>
</template>

<script>
    import { directive as onClickaway } from "vue-clickaway";

    export default {
        directives: {
            onClickaway: onClickaway
        },
        props: {
            position: {
                type: Object,
                default: () => ({
                    x: 0,
                    y: 0
                })
            },
            direction: {
                type: Object
            }
        },
        data: () => ({
            comment: "",
            maxCommentLength: 500
        }),
        methods: {
            clickAway(){
                this.$emit("cancel")
            },
            submitHandler() {
                if (!this.comment.trim()) return;

                this.$emit("post", this.comment);
            }
        }
    };
</script>

<style
  scoped
  lang="scss"
  src="./style.scss"
></style>
